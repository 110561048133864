import React from 'react';
import ReactDOM from 'react-dom/client';
// import App from './App';
import Header from './Header';
import TailwindTemp from './TailwindTemp';
import PlotlyChart from './PlotlyChart';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <div>
    {/* <React.StrictMode> */}
      <Header />
      {/* <TailwindTemp /> */}
      <PlotlyChart />
    
      {/* <App /> */}
    {/* </React.StrictMode> */}
  </div>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
