import React, {useState, useEffect} from 'react';
import Plot from 'react-plotly.js';
import Select from 'react-select'
import './index.css';

// import Radar from './Radar.tsx';
// import ParentSize from '@visx/responsive/lib/components/ParentSize';


// import styled from "styled-components";


export default function PlotlyChart(){
    
    const optionToppings = [
        { value: 'HPG', label: 'HPG - Hoa Phat' },
        { value: 'VCB', label: 'VCB - Vietcombank' },
        { value: 'VIC', label: 'VIC - Vingroup' },
        { value: 'VHM', label: 'VHM - Vinhome' },
        { value: 'VPB', label: 'VPB - VPBank' },
        { value: 'BID', label: 'BID - BIDV' },
        { value: 'TPB', label: 'TPB - Tien Phong Bank' },
        { value: 'HSG', label: 'HSG - Hoa Sen'},
        { value: 'FPT', label: 'FPT - cty FPT' },
        { value: 'TCB', label: 'TCB - Techcombank' },
        { value: 'VNM', label: 'VNM - Vinamik' },
        { value: 'GMD', label: 'GMD - Germadep' },
        { value: 'CTD', label: 'CTD - Contecon' },
        { value: 'VHM', label: 'VHM - Vinhome' },
        { value: 'NVL', label: 'NVL - Novaland' },
        { value: 'DXG', label: 'DXG - Dat Xanh' },
        { value: 'MSN', label: 'MSN - Masan' },
        { value: 'NLG', label: 'NLG - Nam Long' },
    ]

    const [stockData, setStockData] = useState({'data':[]});
    const [stockReturn, setStockReturn] = useState({'data':[]});
    const [treeMap, setTreeMap] = useState({'data':[]});
    const [ridgeLine, setRidgeLine] = useState({'data':[]});
    const [toppings, setToppings] = useState([optionToppings[2], optionToppings[3],  optionToppings[6]]);
    
    useEffect(() => {
    function fetchData(tickers_input){
        const ticker_str = toppings.map(stock_list => stock_list.value).join(" ")
        // const prod_url = 'https://fastmetric.herokuapp.com/items'
        const prod_url = 'https://portfolio-api.herokuapp.com/items'
        // const prod_url = 'https://dautu.up.railway.app/items'
        // const local_url = 'http://127.0.0.1:8000/items'
        fetch(prod_url, {
            method: 'POST', 
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({"tickers_input": ticker_str } )
            })
            .then(response => response.json())
            .then(data => {
                console.log('submited:', data['monthly_returns']);
                setStockData(data['monthly_returns'])
                setStockReturn(data['normalized_price'])
                setTreeMap(data['treemap'])
                setRidgeLine(data['ridgeline'])

            })
            .catch((error) => {
                console.error('Error:', error);
        });
    }
    

    
        fetchData();
    }, [toppings]);
    
    const margin= {
        // t: 50,
        l: 5,
        r: 45,
        b: 35
    };

    const config = {displayModeBar: false}
    
    // const layout= {  
    //     autosize: true,
    //     // width: "1000", 
    //     // height: "500"
    // };

    // const Header = styled.div`
    // top: 10px;
    // margin-bottom: 10px;
    // // position:-webkit-sticky;
    // // position: sticky;
    // `;
    
    return (
        <div className='bg-white'>
            <div className="lg:container lg:mx-auto px-4 sm:px-6 lg:px-8">

            <h2 className="text-2xl text-indigo-600 font-semibold tracking-wide uppercase pt-10">Pick stocks</h2>
            
            <div className='stock-list'>
                
                <Select
                    menuPlacement="top"
                    defaultValue={[optionToppings[2], optionToppings[3],  optionToppings[6]]}
                    isMulti
                    onChange={setToppings}
                    name="stocks"
                    options={optionToppings}
                    className="basic-multi-select mb-3"
                    classNamePrefix="select"
                />
            </div>
            
            
               
            
            <div className='test-button' style={{visibility: 'hidden'}}>
                {/* <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"  onClick={() => fetchData()}>Get info</button>
                <button onClick={() => setStockData({ data: [] })}>Category A</button>
                <button onClick={() => fetchData('VCB HPG CTD') }> Category B</button>
                <button onClick={() => fetchData('VCB BID MWG TCB') }> Category B</button> */}
            </div>
            <div className='grid grid-cols-4 gap-4 my-plot' style={{marginTop: '0.5em'}}>
                <div className='md:col-span-2 col-span-4'>
                    <div className='rounded-lg overflow-hidden'>
                    <Plot
                            data={treeMap['data']}
                        
                            layout={{title: 'Correlation', margin: {t:50, l:40, r:10, b:45}, autosize: true }} // , yaxis:{'side': 'right'}
                            config={config}
                            style={{width: "100%", height: "100%"}}

                        />
                    </div>
                </div>

                <div className='md:col-span-2 col-span-4'>
                <div className='rounded-lg overflow-hidden'>
                <Plot
                        data={ridgeLine['data']}
                        layout={{title: 'Daily Returns Distribution', margin: {t:50, l:40, r:10, b:40}, autosize: true, height: "500" ,showlegend: false }} // , yaxis:{'side': 'right'}  , legend: {x:-0.1, y:1, orientation: 'h'}
                        config={config}
                        style={{width: "100%", height: "100%"}}

                    />
                </div>
                </div>
               
                {/* <div className='col-span-4 md:col-span-2'>
                <ParentSize>{({ width, height }) => <Radar width={width} height='380' />}</ParentSize>
                </div> */}
                <div className='col-span-4'>
                    <Plot
                        data={stockData['data']}
                        // onInitialized={() => fetchData('VCB HPG CTD')}
                        // onUpdate={(figure) => setState(figure)}
                        layout={{title: 'Monthly returns', margin: margin, legend: {x:0, y:1.1, orientation: 'h'} , yaxis:{'side': 'right'}}}
                        config={config}
                        useResizeHandler={true}
                        style={{width: "100%", height: "100%"}}

                    />
                </div>
                <div className='col-span-4'>
                    <Plot
                        data={stockReturn['data']}
                        // onInitialized={() => fetchData('VCB HPG CTD')}
                        // onUpdate={(figure) => setState(figure)}
                        useResizeHandler={true}
                        style={{width: "100%", height: "100%"}}
                        layout={ {title: 'Normalized price', autosize: true, margin: margin,legend: {x:0, y:1.1, orientation: 'h'}, yaxis:{'side': 'right'} }} //orientation: 'h'
                        config={config}
                    />
                </div>
            </div>

        </div>
        </div>

    )
}